import React, { useState } from 'react';

function MyComponent() {

    const [refre, setRefre] = useState();
    const refresh = ()=>{
        setRefre({});
    }

    var min = 5 ;
    var max = 10 ;
    var a = Math.floor( Math.random() * (max + 1 - min) ) + min ;
  
    return (
      <div>
        <button onClick={ refresh }>Refresh Component</button>
        {a}
      </div>
    );
  }
  
  export default MyComponent;